import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Packages from './pages/Packages';
import Card from './pages/Card';
import Shipping from './pages/Shipping';
import Details from './pages/Details';
import Login from './pages/Login';
import Register from './pages/Register';
import { get_category } from './store/reducers/homeReducer';
import { useDispatch } from 'react-redux';
import CategoryShop from './pages/CategoryShop';
import SearchProducts from './pages/SearchProducts';
import Payment from './pages/Payment';
import Dashboard from './pages/Dashboard';
import ProtectUser from './utils/ProtectUser';
import Index from './components/dashboard/Index';
import Orders from './components/dashboard/Orders';
import ChangePassword from './components/dashboard/ChangePassword';
import Wishlist from './components/dashboard/Wishlist';
import OrderDetails from './components/dashboard/OrderDetails';
import Chat from './components/dashboard/Chat';
import ConfirmOrder from './pages/ConfirmOrder';
import Downloads from './components/dashboard/Downloads';
import ContactForm from './components/ContactForm';
import LeadForm from './components/LeadForm';
import Uploads from './components/dashboard/Uploads';
import TransactionManager from './components/dashboard/TransactionManager';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EducationLibrary from './components/dashboard/EducationLibrary';
import Disclosure from './pages/Disclosure';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_category());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/about" element ={<About />} />
        <Route path="/disclosure" element ={<Disclosure />} />
        <Route path="/privacy_policy" element ={<PrivacyPolicy />} />
        <Route path="/contact_us" element={<ContactForm showHeader={true} />} />
        <Route path="/lead_form" element={<LeadForm />} />
        <Route path="/card" element={<Card />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/products?" element={<CategoryShop />} />
        <Route path="/products/search?" element={<SearchProducts />} />
        <Route path="/product/details/:slug" element={<Details />} />
        <Route path="/order/confirm?" element={<ConfirmOrder />} />
        <Route path="/dashboard" element={<ProtectUser />}>
        <Route path="" element={<Dashboard />}>
          <Route path="" element={<Index />} />
          <Route path="my-orders" element={<Orders />} />
          <Route path="education-library" element={<EducationLibrary />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="my-wishlist" element={<Wishlist />} />
          <Route path="order/details/:orderId" element={<OrderDetails />} />
          <Route path="chat" element={<Chat />} />
          <Route path="chat/:sellerId" element={<Chat />} />
          <Route path="downloads" element={<Downloads />} />
          <Route path="uploads" element={<Uploads />} />
          <Route path="transaction-manager" element={<TransactionManager />} />
        </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
