import React, { useEffect, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { IoMdPhonePortrait } from 'react-icons/io';
import {
  FaFacebookF,
  FaInstagram,
  FaList,
  FaLock,
  FaUser,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoMdArrowDropdown } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa6';
import { FaCartShopping } from 'react-icons/fa6';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_card_products,
  get_wishlist_products,
} from '../store/reducers/cardReducer';
import logo from '../assets/logo.png';
import language from '../assets/language.png';
import './Header.css';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categorys } = useSelector((state) => state.home);
  const { userInfo } = useSelector((state) => state.auth);
  const { card_product_count, wishlist_count } = useSelector(
    (state) => state.card
  );

  const { pathname } = useLocation();

  const [showShidebar, setShowShidebar] = useState(true);
  const [categoryShow, setCategoryShow] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState('');

  const search = () => {
    navigate(`/products/search?category=${category}&&value=${searchValue}`);
  };

  const redirect_card_page = () => {
    if (userInfo) {
      navigate('/card');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(get_card_products(userInfo.id));
      dispatch(get_wishlist_products(userInfo.id));
    }
  }, [userInfo]);

  return (
    <div className="w-full bg-white">
      <div className="header-top bg-[#37AFE1] md-lg:hidden">
        <div className="w-[85%] lg:w-[90%] mx-auto">
          <div className="flex w-full justify-between items-center h-[30px] text-white">
            <ul className="flex justify-start items-center gap-8 font-semibold">
              <li className="flex relative justify-center items-center gap-2 text-xs after:absolute after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px]">
                <span className="hover:text-[#024CAA]">
                  <MdEmail />
                </span>
                <span className="hover:text-[#024CAA]">
                  support@diyflexrealty.com
                </span>
              </li>
              <li className="flex relative justify-center items-center gap-2 text-xs">
                <span className="hover:text-[#024CAA]">
                  <IoMdPhonePortrait />
                </span>
                <span className="hover:text-[#024CAA]">+1 (720) 295-0602</span>
              </li>
            </ul>

            <div>
              <div className="flex justify-center items-center gap-10">
                <div className="flex justify-center items-center gap-4">
                  <a
                    href="https://www.facebook.com/people/DIY-Flex-Realty/61566891073607"
                    className="hover:text-[#024CAA]"
                  >
                    <FaFacebookF />
                  </a>
                  <a href="https://x.com/diyflexrealty" className="hover:text-[#024CAA]">
                    <FaXTwitter />
                  </a>
                  <a
                    href="https://www.instagram.com/diyflexrealty"
                    className="hover:text-[#024CAA]"
                  >
                    <FaInstagram />
                  </a>
                </div>
                <div className="flex group cursor-pointer text-xs justify-center items-center gap-1 relative after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px] after:absolute before:absolute before:h-[18px] before:bg-[#afafaf] before:w-[1px] before:-left-[20px]">
                  <img src={language} alt="" />
                  <span className="hover:text-[#024CAA]">
                    <IoMdArrowDropdown />
                  </span>
                  <ul className="absolute invisible transition-all top-12 rounded-sm duration-200 text-white p-2 w-[100px] flex flex-col gap-3 group-hover:visible group-hover:top-6 group-hover:bg-black z-10">
                    <li className="hover:text-[#024CAA]">English</li>
                  </ul>
                </div>

                {userInfo ? (
                  <Link
                    className="flex cursor-pointer justify-center items-center gap-2 text-xs hover:text-[#024CAA]"
                    to="/dashboard"
                  >
                    <span>
                      <FaUser />
                    </span>
                    <span>{userInfo.name}</span>
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="flex cursor-pointer justify-center items-center gap-2 text-xs hover:text-[#024CAA]"
                  >
                    <span>
                      <FaLock />
                    </span>
                    <span>Login</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-white">
        <div className="w-[85%] lg:w-[90%] mx-auto">
          <div className="h-[50px] md-lg:h-[100px] flex justify-between items-center flex-wrap">
            <div className="md-lg:w-full w-3/12 md-lg:pt-2">
              <div className="flex justify-between items-center h-[60px]">
                {' '}
                {/* Adjust height as needed */}
                <Link to="/" className="flex items-center">
                  <img src={logo} alt="Logo" className="h-[200px]" />{' '}
                  {/* Adjust height as needed */}
                </Link>
                <div
                  className="justify-center items-center w-[30px] h-[30px] bg-white text-slate-600 border border-slate-600 rounded-sm cursor-pointer lg:hidden md-lg:flex xl:hidden hidden"
                  onClick={() => setShowShidebar(false)}
                >
                  <span>
                    <FaList />
                  </span>
                </div>
              </div>
            </div>

            <div className="md:lg:w-full w-9/12 my-4">
              <div className="flex justify-between md-lg:justify-center items-center flex-wrap pl-8">
                <ul className="flex justify-start items-start gap-24 text-xs font-semibold uppercase md-lg:hidden">
                  <li>
                    <Link
                      to="/"
                      className={`p-2 block link-hover ${pathname === '/' ? 'text-[#024CAA]' : 'text-black'} `}
                    >
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/packages"
                      className={`p-2 block link-hover ${pathname === '/packages' ? 'text-[#024CAA]' : 'text-black'} `}
                    >
                      Packages
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className={`p-2 block link-hover ${pathname === '/about' ? 'text-[#024CAA]' : 'text-black'} `}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact_us"
                      className={`p-2 block link-hover ${pathname === '/contact_us' ? 'text-[#024CAA]' : 'text-black'} `}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <div className="flex md-lg:hidden justify-center items-center gap-5">
                  <div className="flex justify-center gap-5">
                    <div
                      onClick={() =>
                        navigate(userInfo ? '/dashboard/my-wishlist' : '/login')
                      }
                      className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
                    >
                      <span className="text-xl text-sky-500">
                        <FaHeart />
                      </span>

                      {wishlist_count !== 0 && (
                        <div className="w-[20px] h-[20px] absolute bg-red-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px] ">
                          {wishlist_count}
                        </div>
                      )}
                    </div>

                    <div
                      onClick={redirect_card_page}
                      className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]"
                    >
                      <span className="text-xl text-sky-500">
                        <FaCartShopping />
                      </span>

                      {card_product_count !== 0 && (
                        <div className="w-[20px] h-[20px] absolute bg-red-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px] ">
                          {card_product_count}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md-lg:block">
        <div
          onClick={() => setShowShidebar(true)}
          className={`fixed duration-200 transition-all ${showShidebar ? 'invisible' : 'visible'} hidden md-lg:block w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0 z-20 `}
        ></div>

        <div
          className={`w-[300px] z-[9999] transition-all duration-200 fixed ${showShidebar ? '-left-[300px]' : 'left-0 top-0'} overflow-y-auto bg-white h-screen py-6 px-8 `}
        >
          <div className="flex justify-start flex-col gap-6">
            <Link to="/">
              <img src="http://localhost:3000/images/logo.png" alt="" />
            </Link>
            <div className="flex justify-start items-center gap-10">
              <div className="flex group cursor-pointer text-slate-800 text-sm justify-center items-center gap-1 relative after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px] after:absolute ">
                <img src="http://localhost:3000/images/language.png" alt="" />
                <span>
                  <IoMdArrowDropdown />
                </span>
                <ul className="absolute invisible transition-all top-12 rounded-sm duration-200 text-white p-2 w-[100px] flex flex-col gap-3 group-hover:visible group-hover:top-6 group-hover:bg-black z-10">
                  <li>Hindi</li>
                  <li>English</li>
                </ul>
              </div>
              {userInfo ? (
                <Link
                  className="flex cursor-pointer justify-center items-center gap-2 text-sm text-black"
                  to="/dashboard"
                >
                  <span>
                    {' '}
                    <FaUser />{' '}
                  </span>
                  <span>{userInfo.name}</span>
                </Link>
              ) : (
                <Link
                  className="flex cursor-pointer justify-center items-center gap-2 text-sm text-black"
                  to="/login"
                >
                  <span>
                    {' '}
                    <FaLock />{' '}
                  </span>
                  <span>Login </span>
                </Link>
              )}
            </div>

            <ul className="flex flex-col justify-start items-start text-sm font-bold uppercase">
              <li>
                <Link
                  className={`py-2 block ${pathname === '/' ? 'text-[#37AFE1]' : 'text-slate-600'} `}
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to="/packages"
                  className={`py-2 block ${pathname === '/packages' ? 'text-[#37AFE1]' : 'text-slate-600'} `}
                >
                  Shop
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={`py-2 block ${pathname === '/about' ? 'text-[#37AFE1]' : 'text-slate-600'} `}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact_us"
                  className={`py-2 block ${pathname === '/contact_us' ? 'text-[#37AFE1]' : 'text-slate-600'} `}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <div className="flex justify-start items-center gap-4 text-black">
              <a href="https://www.facebook.com/people/DIY-Flex-Realty/61566891073607">
                <FaFacebookF />
              </a>
              <a href="https://x.com/diyflexrealty">
                <FaXTwitter />{' '}
              </a>
              <a href="https://www.instagram.com/diyflexrealty">
                <FaInstagram />
              </a>Featured 
            </div>

            <div className="w-full flex justify-end md-lg:justify-start gap-3 items-center">
              <div className="w-[48px] h-[48px] rounded-full flex bg-[#f5f5f5] justify-center items-center ">
                <span>
                  <FaPhoneAlt />
                </span>
              </div>
              <div className="flex justify-end flex-col gap-1">
                <h2 className="text-sm font-medium text-slate-700">
                  +134343455
                </h2>
                <span className="text-xs">Support 24/7</span>
              </div>
            </div>

            <ul className="flex flex-col justify-start items-start gap-3 text-[#1c1c1c]">
              <li className="flex justify-start items-center gap-2 text-sm">
                <span>
                  <MdEmail />
                </span>
                <span>support@yahoo.com</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-[85%] lg:w-[90%] mx-auto my-6">
        <div className="flex w-full flex-wrap md-lg:gap-8">
          <div className="w-3/12 md-lg:w-full">
            <div className="bg-white shadow-lg rounded-lg relative transition-shadow duration-300">
              <div
                onClick={() => setCategoryShow(!categoryShow)}
                className="h-[50px] bg-[#024CAA] text-white flex justify-center md-lg:justify-between md-lg:px-6 items-center gap-3 font-bold text-md cursor-pointer hover:shadow-md"
              >
                <div className="flex justify-center items-center gap-3">
                  <span>
                    <FaList />
                  </span>
                  <span>All Categories</span>
                </div>
                <span className="pt-1">
                  <IoIosArrowDown />
                </span>
              </div>

              <div
                className={`${categoryShow ? 'h-0' : 'h'} overflow-hidden transition-all md-lg:relative duration-500 absolute z-10 bg-[#C4E1F6] w-full border-x rounded-b-xl`}
              >
                <ul className="py-2 text-slate-600 font-medium">
                  {categorys.map((c, i) => (
                    <li
                      key={i}
                      className="flex justify-start items-center gap-2 px-[24px] py-[6px] hover:bg-[#024CAA] hover:text-white transition-colors duration-300"
                    >
                      <img
                        src={c.image}
                        className="w-[30px] h-[30px] rounded-full overflow-hidden"
                        alt={c.name}
                      />
                      <Link
                        to={`/products?category=${c.name}`}
                        className="text-sm block"
                      >
                        {c.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="w-9/12 pl-8 md-lg:pl-0 md-lg:w-full">
            <div className="flex flex-wrap w-full justify-between items-center md-lg:gap-6">
              <div className="w-8/12 md-lg:w-full">
                <div className="flex border h-[50px] items-center relative gap-6 rounded-lg shadow-md bg-white">
                  <div className="relative after:absolute after:h-[25px] after:w-[1px] after:bg-[#afafaf] after:-right-[15px] md:hidden">
                    <select
                      onChange={(e) => setCategory(e.target.value)}
                      className="w-[180px] text-slate-600 font-semibold bg-transparent px-2 h-full outline-0 border-none rounded-lg"
                    >
                      <option value="">Select Category</option>
                      {categorys.map((c, i) => (
                        <option key={i} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    className="w-full relative bg-transparent text-slate-500 outline-0 px-3 h-full rounded-lg"
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="text"
                    placeholder="What do you need"
                  />
                  <button
                    onClick={search}
                    className="bg-[#024CAA] right-0 absolute px-8 h-full font-semibold uppercase text-white rounded-lg hover:bg-[#023b8e] transition-colors duration-300"
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="w-4/12 block md-lg:hidden pl-2 md-lg:w-full md-lg:pl-0">
                <div className="w-full flex justify-end md-lg:justify-start gap-3 items-center">
                  <div className="w-[48px] h-[48px] rounded-full flex bg-[#f5f5f5] justify-center items-center shadow-md">
                    <span>
                      <FaPhoneAlt />
                    </span>
                  </div>
                  <div className="flex justify-end flex-col gap-1">
                    <h2 className="text-md font-medium text-slate-700">
                      +1 (720) 295-0602
                    </h2>
                    <span className="text-sm text-gray-500">Support 24/7</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
