import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import './ContactForm.css';
import { lead_submit } from '../store/reducers/homeReducer';

const LeadForm = () => {
  const dispatch = useDispatch();
  const { successMessage, errorMessage } = useSelector((state) => state.home);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    bedrooms: '',
    bathrooms: '',
    squareFootage: '',
    floors: '',
    basement: '',
    yearBuilt: '',
    lotSize: '',
    garageType: '',
    specialFeatures: '',
    recentUpgrades: '',
    knownIssues: '',
    hoaMembership: '',
    zoningDetails: '',
    sellingTimeline: '',
  });

  const inputHandle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(lead_submit(formData));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const inputs = document.querySelectorAll('.input');

    const focusFunc = function () {
      let parent = this.parentNode;
      parent.classList.add('focus');
    };

    const blurFunc = function () {
      let parent = this.parentNode;
      if (this.value === '') {
        parent.classList.remove('focus');
      }
    };

    inputs.forEach((input) => {
      input.addEventListener('focus', focusFunc);
      input.addEventListener('blur', blurFunc);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', focusFunc);
        input.removeEventListener('blur', blurFunc);
      });
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="contact-form-container">
        <div className="contact-form">
          <div className="contact-info">
            <h3 className="contact-form-title">
              Get 3 Competitive iBuyer Offers in Just 24–72 Hours with the National iBuyer Network™!
            </h3>
            <p className="text">
              Are you ready to sell your property without the hassle? With the National iBuyer Network™, you’ll receive up to 3 competitive cash offers from trusted iBuyers across the nation—all within 24 to 72 hours.
            </p>
            <p className="text">
              <strong>No Showings, No Repairs, No Stress:</strong> Simplify your home-selling process by choosing the offer that works best for you.
            </p>
            <p className="text">
              <strong>Fair and Transparent:</strong> Our iBuyer partners use market data and advanced algorithms to ensure competitive pricing for your property.
            </p>
            <p className="text">
              <strong>Quick and Convenient:</strong> Skip the waiting game. With offers in as little as one day, you can focus on what matters most—your next move.
            </p>
            <h4 className="text">
              <strong>Why Choose the National iBuyer Network™?</strong>
            </h4>
            <ul className="text">
              <li><strong>Nationwide Reach:</strong> Access to iBuyers operating in markets across the country.</li>
              <li><strong>Professional Guidance:</strong> Receive support from experienced transaction brokers every step of the way.</li>
              <li><strong>Flexible Timeline:</strong> Choose when to close, whether it’s in a week or a month.</li>
            </ul>
          </div>

          <div className="contact-form-inner">
            <form onSubmit={submitForm} autoComplete="off">
              <h3 className="contact-form-title">Property Information</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  value={formData.name}
                  onChange={inputHandle}
                  required
                />
                <label>Owner's Name</label>
                <span>Owner's Name</span>
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={inputHandle}
                  required
                />
                <label>Email</label>
                <span>Email</span>
              </div>
              
              <div className="input-container">
                <input
                  type="tel"
                  name="phoneNumber"
                  className="input"
                  value={formData.phoneNumber}
                  onChange={inputHandle}
                  required
                />
                <label>Phone Number</label>
                <span>Phone Number</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="line1"
                  className="input"
                  value={formData.line1}
                  onChange={inputHandle}
                  required
                />
                <label>Property Address (Line 1)</label>
                <span>Property Address (Line 1)</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="line2"
                  className="input"
                  value={formData.line2}
                  onChange={inputHandle}
                />
                <label>Address Line 2</label>
                <span>Address Line 2</span>
              </div>

              <div className="input-row">
                <div className="input-container half-width">
                  <input
                    type="text"
                    name="city"
                    className="input"
                    value={formData.city}
                    onChange={inputHandle}
                    required
                  />
                  <label>City</label>
                  <span>City</span>
                </div>
              <div className="input-container half-width">
                  <input
                    type="text"
                    name="state"
                    className="input"
                    value={formData.state}
                    onChange={inputHandle}
                    required
                  />
                  <label>State</label>
                  <span>State</span>
                </div>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="zip"
                  className="input"
                  value={formData.zip}
                  onChange={inputHandle}
                  required
                />
                <label>ZIP Code</label>
                <span>ZIP Code</span>
              </div>

              <div className="input-container">
                <input
                  type="number"
                  name="bathrooms"
                  className="input"
                  value={formData.bathrooms}
                  onChange={inputHandle}
                  required
                />
                <label>Number of Bathrooms</label>
                <span>Number of Bathrooms</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="squareFootage"
                  className="input"
                  value={formData.squareFootage}
                  onChange={inputHandle}
                  required
                />
                <label>Square Footage</label>
                <span>Square Footage</span>
              </div>

              <div className="input-container">
                <input
                  type="number"
                  name="floors"
                  className="input"
                  value={formData.floors}
                  onChange={inputHandle}
                  required
                />
                <label>Number of Floors</label>
                <span>Number of Floors</span>
              </div>

              <div className="input-container">
                <label htmlFor="basement">
                    Basement
                </label>
                <select
                  id="basement"
                  name="basement"
                  className="input select-input"
                  value={formData.basement}
                  onChange={inputHandle}
                  required
                >
                  <option value="" disabled>
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>


              <div className="input-container">
                <input
                  type="number"
                  name="yearBuilt"
                  className="input"
                  value={formData.yearBuilt}
                  onChange={inputHandle}
                  required
                />
                <label>Year Built</label>
                <span>Year Built</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="lotSize"
                  className="input"
                  value={formData.lotSize}
                  onChange={inputHandle}
                  required
                />
                <label>Lot Size</label>
                <span>Lot Size</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="garageType"
                  className="input"
                  value={formData.garageType}
                  onChange={inputHandle}
                  required
                />
                <label>Type of Garage</label>
                <span>Type of Garage</span>
              </div>

              <div className="input-container">
                <textarea
                  name="specialFeatures"
                  className="input"
                  value={formData.specialFeatures}
                  onChange={inputHandle}
                  rows="3"
                  required
                />
                <label>Special Features</label>
                <span>Special Features</span>
              </div>

              <div className="input-container">
                <textarea
                  name="recentUpgrades"
                  className="input"
                  value={formData.recentUpgrades}
                  onChange={inputHandle}
                  rows="3"
                  required
                />
                <label>Recent Upgrades</label>
                <span>Recent Upgrades</span>
              </div>

              <div className="input-container">
                <textarea
                  name="knownIssues"
                  className="input"
                  value={formData.knownIssues}
                  onChange={inputHandle}
                  rows="3"
                  required
                />
                <label>Known Issues</label>
                <span>Known Issues</span>
              </div>

              <div className="input-container">
                <label htmlFor="hoaMembership">
                    HOA Membership
                </label>
                <select
                  id="hoaMembership"
                  name="hoaMembership"
                  className="input select-input"
                  value={formData.hoaMembership}
                  onChange={inputHandle}
                  required
                >
                  <option value="" disabled>
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="zoningDetails"
                  className="input"
                  value={formData.zoningDetails}
                  onChange={inputHandle}
                  required
                />
                <label>Zoning Details</label>
                <span>Zoning Details</span>
              </div>

              <div className="input-container">
                <label htmlFor="sellingTimeline" className="select-label">
                  Selling Timeline
                </label>
                <select
                  id="sellingTimeline"
                  name="sellingTimeline"
                  className="input select-input"
                  value={formData.sellingTimeline}
                  onChange={inputHandle}
                  required
                >
                  <option value="" disabled>
                  </option>
                  <option value="ASAP">ASAP</option>
                  <option value="2-4 weeks">2-4 weeks</option>
                  <option value="4-6 weeks">4-6 weeks</option>
                  <option value="just browsing">Just Browsing</option>
                </select>
                <span className="helper-text">Choose the timeframe that works for you</span>
              </div>

              <button type="submit" className="btn">
                Get Offer
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LeadForm;
