import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { get_banners } from '../store/reducers/homeReducer';
import './Banner.css';
import ProductCard from './products/ProductCard';
import BannerImage from '../assets/Banner.jpeg';
import { add_to_card, add_to_wishlist } from '../store/reducers/cardReducer';
import { useNavigate } from 'react-router-dom';

const Banner = ({ products }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const banners = useSelector((state) => state.home.banners);
  const navigate = useNavigate();
  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate('/login');
    }
  };

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };
  useEffect(() => {
    dispatch(get_banners());
  }, [dispatch]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const maxCostProduct = products.reduce((maxProduct, currentProduct) => 
    currentProduct.price > (maxProduct?.price || 0) ? currentProduct : maxProduct, 
    null
  );
  

  return (
    <div className="w-full md-lg:mt-6">
      <div className="w-[100%]">
        <div className="w-full flex flex-wrap md-lg:gap-8">
          <div className="w-full">
            <div>
              <Carousel
                autoPlay={banners.length > 1}
                infinite={true}
                arrows={false}
                showDots={false}
                responsive={responsive}
              >
                {banners.length > 0 ? (
                  banners.map((b, i) => (
                    <div
                      key={i}
                      className="banner-container"
                      style={{ backgroundImage: `url(${b.banner})` }}
                    >
                      <div className="banner-wrapper">
                        <div className="bannerSubContainer">
                          <div className="bannerHeadingContainer">
                            <h2 className="bannerHeading">
                              Stress-Free and Fast!
                            </h2>
                            <p className="bannerDetail">
                              Your Home Sold – Hassle-Free, Guaranteed!
                            </p>
                          </div>
                          <div className="product-grid">
                            {maxCostProduct && (
                              <ProductCard
                                product={maxCostProduct}
                                addCard={add_card}
                                addWishlist={add_wishlist}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="banner-container"
                    style={{ backgroundImage: `url(${BannerImage})` }}
                  >
                    <div className="banner-wrapper">
                      <div className="bannerSubContainer">
                        <div className="bannerHeadingContainer">
                          <h2 className="bannerHeading">
                            Stress-Free and Fast!
                          </h2>
                          <p className="bannerDetail">
                            Your Home Sold – Hassle-Free, Guaranteed!
                          </p>
                        </div>
                        <div className="product-grid">
                          {maxCostProduct && (
                            <ProductCard
                              product={maxCostProduct}
                              addCard={add_card}
                              addWishlist={add_wishlist}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Carousel>
              <p className="text-center mt-4">DIY Flex Realty (™) is an independently owned and operated brokerage, License Number 100077674 | Connect with Ready Buyers Through Our National iBuyer Network™!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
