import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Disclosure = () => {
  return (
    <div>
      <Header />
      <div className="bg-gray-50 min-h-screen py-10">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Disclosure</h1>

            <p className="text-gray-700 leading-relaxed mb-4">
              DIY Flex Realty, operated by Gregory Reichmuth, is a licensed real
              estate brokerage in the State of Colorado (License #100077674). As an
              independent broker, Gregory Reichmuth provides real estate services
              exclusively for properties located within Colorado. This website and
              the services offered are intended for Colorado residents only and are
              not intended to solicit business in other states.
            </p>

            <p className="text-gray-700 leading-relaxed mb-4">
              DIY Flex Realty fully supports the principles of the Fair Housing Act
              and the Equal Opportunity Act. We are committed to providing equal
              housing opportunities to all individuals.
            </p>

            <p className="text-gray-700 leading-relaxed mb-4">
              Please note that referrals to brokers in other states may result in a
              referral fee to DIY Flex Realty. This does not establish an agency
              relationship outside Colorado.
            </p>

            <p className="text-gray-700 leading-relaxed mb-4">
              All information provided on this website is for general informational
              purposes only and should not be considered professional or legal
              advice. By using this website, you agree to our Privacy Policy and
              Terms of Use.
            </p>

            <div className="mt-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Information:</h2>
              <ul className="list-none text-gray-700 leading-relaxed">
                <li className="mb-2">
                  <strong>DIY Flex Realty</strong>
                </li>
                <li className="mb-2">
                  Gregory Reichmuth – Colorado Independent Broker
                </li>
                <li className="mb-2">License #: 100077674</li>
                <li className="mb-2">Email: DIYFlexRealty@gmail.com</li>
                <li className="mb-2">Phone: 720-295-0602</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Disclosure;
