import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  add_to_card,
  add_to_wishlist,
  messageClear,
} from '../../store/reducers/cardReducer';
import toast from 'react-hot-toast';
import ProductCard from './ProductCard';
import './FeatureProducts.css';

const FeatureProducts = ({ products }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { errorMessage, successMessage } = useSelector((state) => state.card);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 2;

  const productNamesEnv = process.env.REACT_APP_DISPLAY_PRODUCT_NAMES
    ? process.env.REACT_APP_DISPLAY_PRODUCT_NAMES.split(',')
    : [];

  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };

  const filteredProducts = products.filter((product) => {
    return productNamesEnv.includes(product.brand.toString());
  });


  return (
    <div className="featuringContainer">
      <div className="heading">
        <h2>
          Featured <span className="headingText">DIY Packages</span>{' '}
        </h2>
        <p className="headingDesc">
          Selling your home can be a complex process, but DIY Flex Realty’s tailored packages are designed to address the common challenges sellers face. Whether you’re navigating the market for the first time or seeking full-service support, we provide the tools and guidance to help you achieve a successful sale.
          <br /><br />
          <strong>How Our Packages Address Common Selling Challenges:</strong>
          <br />
          <strong>Standing Out in a Crowded Market:</strong> Showcase your property with professional marketing tools like photography and virtual tours.
          <br />
          <strong>Setting the Right Price:</strong> Gain access to resources and expertise to price your home competitively.
          <br />
          <strong>Avoiding Delays:</strong> Attract more buyers faster by presenting your property at its best.
          <br />
          <strong>Managing Costs:</strong> Choose a package that fits your needs and budget to minimize unnecessary expenses.
          <br />
          <strong>Simplifying the Process:</strong> Reduce stress with tools and full transaction management to streamline your experience.
          <br /><br />
          <strong>Each package is designed to help you:</strong>
          <br />
          <strong>Reach More Buyers:</strong> Stand out in the market with visually compelling and effective marketing.
          <br />
          <strong>Showcase Your Home:</strong> Present your property in the best possible light to attract competitive offers.
          <br />
          <strong>Navigate the Sale Process with Ease:</strong> Streamline every step with expert tools and support.
          <br /><br />
          Ready to take the stress out of selling? Choose the right package today and take control of your property sale with DIY Flex Realty!
        </p>
      </div>

      <div className="productContainer">
        {filteredProducts
          .slice(currentIndex, currentIndex + itemsPerPage)
          .map((p, i) => (
            <div className="productCard" key={i}>
              <ProductCard
                product={p}
                addCard={add_card}
                addWishlist={add_wishlist}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FeatureProducts;
