import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import './Category.css';

const Categorys = ({ categorys }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="categorys-container">
      <div className="category-subContainer">
        <div className="category-heading">
          <h2 className="text-black">
            Explore Our Top <span className="category-text">DIY flexible</span> packages
          </h2>
          <p className="category-desc">
            Find Your Perfect Property-Selling Solution
            At DIY Flex Realty, we offer specialized DIY packages to match your needs, no matter where you are in your selling journey:
            <br />
            <strong>Getting Started?</strong> Essential tools to guide you through the initial steps.
            <br />
            <strong>Need Marketing Support?</strong> Professional resources to showcase your property effectively.
            <br />
            <strong>Prefer Full-Service Assistance?</strong> Comprehensive support for a stress-free sale.
            <br />
            Choose the package that’s right for you and let us provide the tools, expertise, and confidence to navigate your property sale with ease.
          </p>
        </div>
      </div>

      <Carousel
        autoPlay={true}
        infinite={true}
        arrows={true}
        autoPlaySpeed={2000}
        responsive={responsive}
      >
        {categorys.map((c, i) => (
          <Link
            className="category-link"
            key={i}
            to={`/products?category=${c.name}`}
          >
            <div
              key={i}
              className={`flex transition-all duration-500 hover:shadow-lg hover:-translate-y-2 flex-col justify-start items-start w-full gap-4 bg-white p-3 rounded-lg`}
            >
              <div className="w-full relative group h-[210px] md:h-[270px] xs:h-[170px] overflow-hidden rounded-lg">
                <img
                  className="h-[240px] rounded-lg md:h-[270px] xs:h-[170px] w-full object-cover transition-all duration-500 group-hover:scale-105"
                  src={c.image}
                  alt=""
                />
                <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
                <h2 className="absolute top-2 left-2 text-white font-bold text-lg md:text-xl drop-shadow-lg z-20">
                  {c.name}
                </h2>
              </div>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default Categorys;