import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Rating from '../components/Rating';
import { FaHeart, FaXTwitter } from 'react-icons/fa6';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import Reviews from '../components/Reviews';
import 'swiper/css';
import 'swiper/css/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { product_details } from '../store/reducers/homeReducer';
import toast from 'react-hot-toast';
import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from '../store/reducers/cardReducer';

const Details = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { product, relatedProducts, moreProducts } = useSelector(
    (state) => state.home
  );
  const { userInfo } = useSelector((state) => state.auth);
  const { errorMessage, successMessage } = useSelector((state) => state.card);

  useEffect(() => {
    dispatch(product_details(slug));
  }, [slug]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage]);

  const images = [1, 2, 3, 4, 5, 6];
  const [image, setImage] = useState('');
  const discount = 10;
  const stock = 3;
  const [showPopup, setShowPopup] = useState(true);
  const [state, setState] = useState({
    disclosures: {
      agencyRelationship: false,
      noSetFee: false,
      fairHousingAct: false,
      legalFinancialDisclaimer: false,
      leadPaintSeller: false,
      leadPaintBuyer: false,
      finalAcknowledgment: false,
    },
  });

  const handleCheckboxChange = (key) => {
    setState((prevState) => ({
      ...prevState,
      disclosures: {
        ...prevState.disclosures,
        [key]: !prevState.disclosures[key],
      },
    }));
  };

  const canProceed = Object.values(state.disclosures).every((val) => val);

  const handleClosePopup = () => {
    if (canProceed) {
      setShowPopup(false);
    } else {
      toast.error('Please acknowledge all the disclosures before proceeding.');
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };

  const [quantity, setQuantity] = useState(1);

  const inc = () => {
    if (quantity >= product.stock) {
      toast.error('Out of Stock');
    } else {
      setQuantity(quantity + 1);
    }
  };

  const dec = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const add_card = () => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity,
          productId: product._id,
        })
      );
    } else {
      navigate('/login');
    }
  };

  const add_wishlist = () => {
    if (userInfo) {
      dispatch(
        add_to_wishlist({
          userId: userInfo.id,
          productId: product._id,
          name: product.name,
          price: product.price,
          image: product.images[0],
          discount: product.discount,
          rating: product.rating,
          slug: product.slug,
        })
      );
    } else {
      navigate('/login');
    }
  };

  const buynow = () => {
    let price = 0;
    if (product.discount !== 0) {
      price =
        product.price - Math.floor((product.price * product.discount) / 100);
    } else {
      price = product.price;
    }

    const obj = [
      {
        sellerId: product.sellerId,
        shopName: product.shopName,
        price: quantity * (price - Math.floor((price * 5) / 100)),
        products: [
          {
            quantity,
            productInfo: product,
          },
        ],
      },
    ];

    navigate('/shipping', {
      state: {
        products: obj,
        price: price * quantity,
        platform_fee: 20,
        items: 1,
      },
    });
  };

  return (
    <div>
      <Header />
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" style={{ zIndex: 100 }}>
          <div className="bg-white p-6 rounded-md max-h-[90vh] overflow-y-auto w-[90%] md:w-[70%]">
            <h2 className="text-xl font-bold mb-4">DIY Flex Realty: Comprehensive Disclosure and Acknowledgment Form</h2>
            <p className="mb-4">Welcome to DIY Flex Realty. Before proceeding with your purchase of a flat-fee real estate package, it is important to review and acknowledge the following disclosures as required by law. These disclosures are designed to help you understand the broker’s role, your rights, and any legal considerations when selling or buying property.</p>

            <div className="mb-4">
              <h3 className="font-bold">1. Agency Relationship Disclosure</h3>
              <p>In Colorado, you have the right to choose how a broker represents you in a real estate transaction. Please review the three types of brokerage relationships below:</p>
              <ul className="list-disc pl-6">
                <li><strong>Seller’s Agent:</strong> Represents only the seller, negotiates the best terms and price for the seller, and owes fiduciary duties like loyalty, full disclosure, and confidentiality.</li>
                <li><strong>Buyer’s Agent:</strong> Represents only the buyer, negotiates the best terms and price for the buyer, and owes fiduciary duties like loyalty, full disclosure, and confidentiality.</li>
                <li><strong>Transaction Broker (Default Representation):</strong> Assists both the buyer and seller in a neutral capacity, does not owe fiduciary duties, and provides fair and honest treatment to all parties.</li>
              </ul>
              <p><strong>Important Note:</strong> If no agency relationship is selected, the broker will act as a Transaction Broker by default.</p>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.agencyRelationship}
                  onChange={() => handleCheckboxChange('agencyRelationship')}
                />{' '}
                I acknowledge that I have reviewed the explanation of agency relationships and understand that the broker will act as a Transaction Broker if no other relationship is chosen.
              </label>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">2. No Set Fee Disclosure</h3>
              <p>The real estate market does not mandate a set commission or flat fee. Commissions and fees are determined by individual brokerages.</p>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.noSetFee}
                  onChange={() => handleCheckboxChange('noSetFee')}
                />{' '}
                I acknowledge that I understand there is no set fee in the real estate market, and fees are negotiable.
              </label>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">3. Fair Housing Act Disclosure</h3>
              <p>DIY Flex Realty adheres to the principles of the Fair Housing Act, ensuring that no individual is denied housing opportunities based on race, color, religion, sex, disability, familial status, or national origin.</p>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.fairHousingAct}
                  onChange={() => handleCheckboxChange('fairHousingAct')}
                />{' '}
                I acknowledge and understand the Fair Housing Act principles.
              </label>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">4. Legal and Financial Disclaimer</h3>
              <p>DIY Flex Realty strongly recommends that you consult with the following professionals before proceeding:</p>
              <ul className="list-disc pl-6">
                <li><strong>Attorney:</strong> To ensure legal compliance and protect your interests.</li>
                <li><strong>Certified Financial Advisor:</strong> To understand the financial implications of your real estate transaction.</li>
                <li><strong>Licensed Appraiser:</strong> For accurate property valuation.</li>
              </ul>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.legalFinancialDisclaimer}
                  onChange={() => handleCheckboxChange('legalFinancialDisclaimer')}
                />{' '}
                I acknowledge that I have been advised to consult with the appropriate legal, financial, and appraisal experts before proceeding.
              </label>
            </div>

            <div className="mb-4">
              <h3 className="font-bold">5. Lead-Based Paint Disclosure</h3>
              <p>If the property was built before 1978, federal law requires sellers to disclose any known presence (or potential presence) of lead-based paint or lead-based paint hazards. Please review the following:</p>
              <ul className="list-disc pl-6">
                <li><strong>Seller Responsibilities:</strong> Provide buyers with an EPA-approved pamphlet titled <em>Protect Your Family from Lead in Your Home</em>, disclose any known information about lead-based paint or lead hazards, and allow buyers a 10-day period to conduct a lead-based paint inspection or risk assessment if they choose.</li>
                <li><strong>Buyer Acknowledgment:</strong> Buyers must acknowledge receipt of the pamphlet and any relevant disclosures.</li>
              </ul>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.leadPaintSeller}
                  onChange={() => handleCheckboxChange('leadPaintSeller')}
                />{' '}
                I acknowledge that if the property was built before 1978, I am required to disclose any known information regarding lead-based paint hazards and provide the EPA pamphlet <em>Protect Your Family from Lead in Your Home</em>.
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.leadPaintBuyer}
                  onChange={() => handleCheckboxChange('leadPaintBuyer')}
                />{' '}
                I acknowledge receipt of the pamphlet <em>Protect Your Family from Lead in Your Home</em> and understand my right to a lead-based paint inspection or risk assessment.
              </label>
            </div>

            <div className="mb-6">
              <h3 className="font-bold">6. Final Acknowledgment</h3>
              <p>By checking the box below, you confirm that you have read, understood, and acknowledged all disclosures provided above. You may proceed with your purchase whether or not you choose an agency relationship.</p>
              <br></br>
              <label>
                <input
                  type="checkbox"
                  checked={state.disclosures.finalAcknowledgment}
                  onChange={() => handleCheckboxChange('finalAcknowledgment')}
                />{' '}
                I confirm that I have read and understand all disclosures outlined above.
              </label>
            </div>

            <button
              type="button"
              onClick={handleClosePopup}
              className="px-4 py-2 bg-green-500 text-white rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <section className='bg-[url("http://localhost:3000/images/banner/shop.png")] h-[220px] mt-6 bg-cover bg-no-repeat relative bg-left'>
        <div className="absolute left-0 top-0 w-full h-full bg-[#2422228a]">
          <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex flex-col justify-center gap-1 items-center h-full w-full text-white">
              <h2 className="text-3xl font-bold">Property Details </h2>
              <div className="flex justify-center items-center gap-2 text-2xl w-full">
                <Link to="/">Home</Link>
                <span className="pt-1">
                  <IoIosArrowForward />
                </span>
                <span>Property Details </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-slate-100 py-5 mb-5">
          <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex justify-start items-center text-md text-slate-600 w-full">
              <Link to="/">Home</Link>
              <span className="pt-1">
                <IoIosArrowForward />
              </span>
              <Link to="/">{product.category}</Link>
              <span className="pt-1">
                <IoIosArrowForward />
              </span>
              <span>{product.name} </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto pb-16">
          <div className="grid grid-cols-2 md-lg:grid-cols-1 gap-8">
            <div>
              <div className="p-5 border">
                <img
                  className="h-[400px] w-full"
                  src={image ? image : product.images?.[0]}
                  alt=""
                />
              </div>
              <div className="py-3">
                {product.images && (
                  <Carousel
                    autoPlay={true}
                    infinite={true}
                    responsive={responsive}
                    transitionDuration={500}
                  >
                    {product.images.map((img, i) => {
                      return (
                        <div key={i} onClick={() => setImage(img)}>
                          <img
                            className="h-[120px] cursor-pointer"
                            src={img}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <div className="text-3xl text-slate-600 font-bold">
                <h3>{product.name} </h3>
              </div>
              <div className="flex justify-start items-center gap-4">
                <div className="flex text-xl">
                  <Rating ratings={4.5} />
                </div>
                <span className="text-sky-500">(24 reviews)</span>
              </div>

              <div className="text-2xl text-green-500 font-bold flex gap-3">
                {product.discount !== 0 ? (
                  <>
                    Price : <h2 className="line-through">${product.price}</h2>
                    <h2>
                      $
                      {product.price -
                        Math.floor(
                          (product.price * product.discount) / 100
                        )}{' '}
                      (-{product.discount}%){' '}
                    </h2>
                  </>
                ) : (
                  <h2> Price : ${product.price} </h2>
                )}
              </div>

              <div className="text-slate-600">
                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </div>

              <div className="flex gap-3 pb-10 border-b">
                {product.stock ? (
                  <>
                    <div className="flex bg-slate-200 h-[50px] justify-center items-center text-xl">
                      <div onClick={dec} className="px-6 cursor-pointer">
                        -
                      </div>
                      <div className="px-6">{quantity}</div>
                      <div onClick={inc} className="px-6 cursor-pointer">
                        +
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={add_card}
                        className="px-8 py-3 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-green-500/40 bg-sky-500 text-white"
                      >
                        Add To Card
                      </button>
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div>
                  <div
                    onClick={add_wishlist}
                    className="h-[50px] w-[50px] flex justify-center items-center cursor-pointer hover:shadow-lg hover:shadow-cyan-500/40 bg-sky-500 text-white"
                  >
                    <FaHeart />
                  </div>
                </div>
              </div>

              <div className="flex py-5 gap-5">
                <div className="w-[150px] text-black font-bold text-xl flex flex-col gap-5">
                  <span>Share On</span>
                </div>
                <div className="flex flex-col gap-5">
                  <ul className="flex justify-start items-center gap-3">
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-indigo-500 rounded-full text-white"
                        href="https://www.facebook.com/people/DIY-Flex-Realty/61566891073607"
                      >
                        {' '}
                        <FaFacebookF />{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-black rounded-full text-white"
                        href="#"
                      >
                        {' '}
                        <FaXTwitter />{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        className="w-[38px] h-[38px] hover:bg-[#37AFE1] hover:text-white flex justify-center items-center bg-purple-500 rounded-full text-white"
                        href="https://www.instagram.com/diyflexrealty"
                      >
                        {' '}
                        <FaInstagram />{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex gap-3">
                {product.stock ? (
                  <button
                    onClick={buynow}
                    className="px-8 py-3 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-green-500/40 bg-[#247462] text-white"
                  >
                    Buy Now
                  </button>
                ) : (
                  ''
                )}
                <Link
                  to={`/dashboard/chat/${product.sellerId}`}
                  className="px-8 py-3 h-[50px] cursor-pointer hover:shadow-lg hover:shadow-red-500/40 bg-red-500 text-white"
                >
                  Chat Seller
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto pb-16">
          <div className="flex flex-wrap">
            <div className="w-[72%] md-lg:w-full">
              <div className="pr-4 md-lg:pr-0">
                <div className="grid grid-cols-2">
                  <button
                    onClick={() => setState('reviews')}
                    className={`py-1 hover:text-white px-5 hover:bg-[#024CAA] ${state === 'reviews' ? 'bg-[#37AFE1] text-white' : 'bg-slate-200 text-slate-700'} rounded-sm`}
                  >
                    Reviews{' '}
                  </button>

                  <button
                    onClick={() => setState('description')}
                    className={`py-1 hover:text-white px-5 hover:bg-[#024CAA] ${state === 'description' ? 'bg-[#37AFE1] text-white' : 'bg-slate-200 text-slate-700'} rounded-sm`}
                  >
                    Description{' '}
                  </button>
                </div>

                <div>
                  {state === 'reviews' ? (
                    <Reviews product={product} />
                  ) : (
                    <div
                      className="py-5 text-slate-600"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="w-[28%] md-lg:w-full">
              <div className="pl-4 md-lg:pl-0">
                <div className="px-3 py-2 text-slate-600 bg-slate-200">
                  <h2 className="font-bold">From DIY Flex Realty</h2>
                </div>
                <div className="flex flex-col gap-5 mt-3 border p-3">
                  {moreProducts.map((p, i) => {
                    return (
                      <Link className="block">
                        <div className="relative h-[270px]">
                          <img
                            className="w-full h-full"
                            src={p.images[0]}
                            alt=""
                          />
                          {p.discount !== 0 && (
                            <div className="flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2">
                              {p.discount}%
                            </div>
                          )}
                        </div>

                        <h2 className="text-slate-600 py-1 font-bold">
                          {p.name}{' '}
                        </h2>
                        <div className="flex gap-2">
                          <h2 className="text-lg font-bold text-slate-600">
                            ${p.price}
                          </h2>
                          <div className="flex items-center gap-2">
                            <Rating ratings={p.rating} />
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section></section>
      <Footer />
    </div>
  );
};

export default Details;
